.dragger-parent{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /*border-top: 1px solid gray;*/
}

.ant-upload.ant-upload-drag{
    width: 80%;
    margin: 20px;
}
.ant-upload-list.ant-upload-list-picture{
    width: 80%;
    height: 10%;
    margin: 5px;
}
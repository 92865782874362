.grecaptcha-badge { visibility: hidden; }

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.Desktop {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
}


.appWidth {
  max-width: 600px;
}

.bannerComponent, .index .banner li button {
  background: #5653d7;
  border-radius: 10px;
  /*width: 255px;*/
  height: 311px;
  margin: 3px;
}

.bannerComponent p.title, .index .banner li button p.title {
  font-size: 20px;
  line-height: 24px;
  top: 19px;
  left: 16px;
  width: calc(100% - 32px);
  /*width: 100%;*/
}

.bannerComponent p span, .index .banner li button p span {
  display: block;
  color: inherit;
  line-height: inherit;
  font-size: inherit;
}

p.title {
  font-weight: 700;
}

.bannerComponent img, .index .banner li button img {
  bottom: 19px;
  right: 6px;
  width: 230px;
  height: 176px;
  -o-object-fit: contain;
  object-fit: contain;
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
  margin: 0.2rem;
}

/* side bar 상단 */
.p-sidebar .p-sidebar-header {
  padding: 0.7rem;
}

/* Chatting의 상담원 icon */
.AgentLogo {
    width: 40px;
    height: 37px;
    border-radius: 15px;
    -o-object-fit: cover;
    object-fit: cover;
}

/* Ref: https://projects.verou.me/bubbly/ */
.speech-bubble {
	position: relative;
	background: #ffffff;
	border-radius: .8rem;
  padding: 0.5rem 0.7rem 0.5rem 0.7rem;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 1.5rem;
	width: 0;
	height: 0;
	border: 0.7rem solid transparent;
	border-right-color: #ffffff;
	border-left: 0;
	border-top: 0;
	margin-top: -0.6rem;
	margin-left: -0.5rem;
}

/* 꼬리가 없는 버블 */
.round-bubble {
	background: #ffffff;
	border-radius: .8rem;
  padding: 0.5rem 0.7rem 0.5rem 0.7rem;
}

.bot-bubble-w {
  max-width: calc(100% - 8.5rem);
  width: calc(100% - 8.5rem);
}

.chatButton {
  width: 100%;
  border: 1px solid #e6e2dd;
  /*padding: 5px;*/
  padding: 8px 13px;
  color: #999;
  background: none;
  cursor: pointer;
}

.cb-selected {
  border: 1px solid #ffd118;
  color: #191919;
  background-color: #fff8dc;
}

.cb-disabled {
  border: 1px solid #e6e2dd;
  color: #837b72;
  background-color: #f5f4f3;
  cursor: default;
}

.cb-select-disabled {
  border: 1px solid #ffd118;
  color: #191919;
  background-color: #fff8dc;
  cursor: default;
}

.user-bubble {
	background: #ffd118;
	border-radius: 0.8rem;
  display: inline-block;
}

/* 메인 Theme 오렌지 컬러 백그라운드 */
.orange-back {
  background-color: rgb(255, 209, 24);
  border-color: rgb(255, 209, 24);
}

/* side-bar의 패딩을 없앰. 버그메뉴용 */
.p-sidebar .p-sidebar-content {
  padding: 0;
}

/* faq의 accordion header */
.faq-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.7rem;
  border: 1px solid #dee2e6;
  color: #6c757d;
  background: #f8f9fa;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

/* faq accordion content의 padding 줄임 */
.faq-accordion .p-accordion-content {
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

/* faq-acoordion의 > 옆에 마진 줄임 */
.faq-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.1rem;
}

/* faq의 tab body의 padding 줄임 */
.faq-tab-view .p-tabview-panels {
  background: #ffffff;
  padding: 0.5rem 0.1rem ;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

/* faq nv 아래의 파란 줄 애니메이션 삭제 */
.faq-tab-view .p-tabview-ink-bar {
  display: none !important;
}

/* faq nav 아래의 회색줄 제거 */
.faq-tab-view .p-tabview-nav {
  border: none;
}

/* ScrollMenu의 scrollbar를 지움 */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* ReactPlayer 의 rounded border */
.rounded-video .react-player__preview, .rounded-video iframe {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

/* 로그인 할거냐고 묻는 다이얼로그 */
.rounded-dialog .p-dialog-footer {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
    padding: 0.5rem 1rem;
}

/* modal close 버튼 포커스 테두리 없앰 */

.react-responsive-modal-closeButton:focus {
  outline: none;
}

/* 노란색, 받다 테마랑 맞춤 */

.p-togglebutton.p-button.p-highlight {
  background: rgb(255, 209, 24);
  border-color: rgb(255, 209, 24);  
}

